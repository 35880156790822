import React, { useEffect, useState, useRef } from 'react'
import logger from './MyLogger';
import call_backend_api from './APIHandler';

const RollingQuote = () => {

  const renderTimes = useRef(0)
  const [currentElement, setCurrentElement] = useState(0);
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {

    call_backend_api("get_shabat_info_record", "GET", {})
    .then((backend_reponse) => {
      const quoteHeaders = ['q1', 'q2', 'q3', 'q4', 'q5']
          var allQuotes = []
          for (var i = 0; i < quoteHeaders.length; i += 1) {
            var newQuote = backend_reponse['shabat_record']['parasha_record'][quoteHeaders[i]]
            if (newQuote !== undefined && newQuote !== "" && !allQuotes.includes(newQuote)) {
              allQuotes.push(newQuote)
            }
          }
          setQuotes(allQuotes)
    });
      }, [])


    useEffect(() => {
      if ("geolocation" in navigator) {
        logger.log("GPS Available");
      }
      renderTimes.current += 1
      const intervalId = setInterval(() => {
        setCurrentElement((cur) => (cur + 1) % quotes.length);
      }, 5000);

      // Clear the interval when the component unmounts to avoid memory leaks
      return () => {
        clearInterval(intervalId);
      };

    }, [quotes]);


    return (
      <div className='text-base relative w-full h-12'>
        {quotes.map((item, index) => (
          <div key={item} className={` bg-[#ffffff60] h-full w-full absolute top-0 transition-opacity ${index === currentElement ? 'opacity-100 delay-[800ms] duration-[2000ms]' : 'opacity-0 delay-0 duration-[1000ms]'} flex flex-col justify-center items-center`}>
            <p>{item}</p>
          </div>

        ))}
      </div >
    )
  }

export default RollingQuote