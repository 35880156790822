import React, { useState, useEffect, useContext } from 'react';
import Switch from '@mui/material/Switch';
import LoginOverlay from '../components/LoginOverlay';
import { LoginContext } from '../components/LoginContext';
import logger from '../components/MyLogger';
import call_backend_api from '../components/APIHandler';


const UserSettings = () => {

  const [isShabatAlertActive, setIsShabatAlertActive] = useState(null);
  const [isWeekAlertActive, setIsWeekAlertActive] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const { googleUser } = useContext(LoginContext);


  useEffect(() => {
    if (typeof googleUser === 'undefined') {
      return
    }
    call_backend_api("get_client_settings", "POST", {id_token: googleUser})
    .then((backend_reponse) => {
      if ('client_settings' in backend_reponse) {
        logger.log("Backend server responsed with client settings: ", backend_reponse['client_settings'])
        setIsShabatAlertActive(backend_reponse['client_settings']['is_shabat_alert_active'])
        setIsWeekAlertActive(backend_reponse['client_settings']['is_week_alert_active'])
        setSelectedCity(backend_reponse['client_settings']['city'])
      }
    });
  }, [googleUser])


  const shabatAlertSwitchHandler = (event) => {
    setIsShabatAlertActive(event.target.checked);
  };

  const weekAlertSwitchHandler = (event) => {
    setIsWeekAlertActive(event.target.checked);
  };

  const applySettings = () => {
    logger.log("Updating User Settings (", isShabatAlertActive, isWeekAlertActive, selectedCity, ")")

    call_backend_api("update_client_settings", "POST", { id_token: googleUser, client_settings: { is_shabat_alert_active: isShabatAlertActive, is_week_alert_active: isWeekAlertActive, city: selectedCity } })
    .then((backend_reponse) => {})
  }

  return (
    <div className='relative bg-[#ffffff60] shadow-lg w-10/12 h-[90%] rounded-xl flex flex-col items-center gap-6 text-lg'>
      
    <LoginOverlay/>

      <h1 className='tracking-wide mt-16 font-bold text-3xl pt-4'> הגדרות </h1>

      {/* White box */}
      <div className='flex flex-col w-10/12 h-60 overflow-hidden rounded-xl mt-6'>
        {/* Title */}
        <div className='bg-[#ffffff90] pt-1 w-full h-10 font-medium'> תזכורת שבת </div>

        {/* Setting Boxes Container */}
        <div className="flex flex-col w-full justify-between h-56">
          {/* Before Shabat Setting */}
          <div className="flex items-center w-full h-1/3">
            <div className={`w-9/12 h-full flex items-center justify-center ${isShabatAlertActive !== false ? 'bg-[#ffffff60]' : 'bg-[#aaaaaa40]'}`}>
              <span> קבל הודעות כניסת שבת </span>
            </div>
            <div className="w-3/12 h-full flex bg-[#ffffff60] justify-center items-center">
            {isShabatAlertActive !== null ? (
              <Switch checked={isShabatAlertActive ? true : false} onChange={shabatAlertSwitchHandler} />
            ) : null}
            </div>
          </div>
          {/* After Shabat Setting */}
          <div className="flex items-center w-full h-1/3">
            <div className={`w-9/12 h-full flex items-center justify-center ${isWeekAlertActive !== false ? 'bg-[#ffffff60]' : 'bg-[#aaaaaa40]'}`}>
              <span> קבל הודעות שבוע טוב </span>
            </div>
            <div className="w-3/12 h-full flex bg-[#ffffff60] justify-center items-center">
            {isWeekAlertActive !== null? (
              <Switch checked={isWeekAlertActive ? true : false} onChange={weekAlertSwitchHandler} />
            ):null}
            </div>
          </div>

          {/* City Setting */}

          <div className={`w-full h-1/3 flex items-center justify-center bg-[#ffffff60]`}>

            <label className='w-1/5' htmlFor="citySelect"> עיר </label>
            <select id="citySelect"  value={selectedCity !== null? selectedCity : ''} onChange={(event) => { setSelectedCity(event.target.value) }} className={`w-3/5 ${selectedCity !== null ? 'bg-[#ffffffff]' : 'bg-[#aaaaaa40]'}`}>
              <option value="" disabled></option>
              <option value="TelAviv">תל אביב</option>
              <option value="Haifa">חיפה</option>
              <option value="Jerusalem">ירושלים</option>
              <option value="BeerSheva">באר שבע</option>
            </select>

          </div>
        </div>
      </div>

      <button className={`active:bg-[#d4d4d4] w-4/12 bg-[#ffffff60] border-[#ffffffc0] rounded-lg border-2 mt-6 active:bg-[#ffffff20]} ${isWeekAlertActive == null ? 'disabled' :''}`} onClick={applySettings}> אישור </button>
    </div>
  )
}

export default UserSettings
