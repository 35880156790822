import React, { useContext } from "react";
import { FcGoogle } from "react-icons/fc";
import { LoginContext } from "./LoginContext";

const LoginOverlay = () => {
  const { googleUser, handleLogin, } = useContext(LoginContext);

  return (
    <div className={`w-full h-full absolute z-50 ${(typeof googleUser !== 'undefined') ? 'hidden' : ''}`}>

        <div className='flex flex-col bg-slate-500 bg-opacity-70 w-full h-full'>
          <button onClick={handleLogin} className="font-semibold flex m-auto px-4 py-2 border gap-2 items-center bg-blue-600  border-slate-200 rounded-lg text-slate-200">
            <FcGoogle />
            התחבר עם גוגל
          </button>
        </div>
    </div>
  );
};

export default LoginOverlay;
