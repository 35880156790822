import React, { createContext, useEffect, useState } from "react";
import { gapi } from "gapi-script";
import logger from './MyLogger';

const clientId = "427936102880-2kcq2secru3q9083kqf29hcnevvnke9n.apps.googleusercontent.com";

const LoginContext = createContext(); //object

const LoginProvider = ({ children }) => {
  const [googleUser, setGoogleUser] = useState(undefined);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "openid email profile",
        ux_mode: 'redirect', 
      }).then((response) => {
          const auth2 = gapi.auth2.getAuthInstance();
            auth2.isSignedIn.listen(signInChangedListener);
            auth2.currentUser.listen(currentUserChangedListener);
            const token_id = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token;
            setGoogleUser(token_id)
      });
    };
    gapi.load("client:auth2", initClient);

  }, []);


  const signInChangedListener = () =>{
    logger.log("in signInChangedListener")
    const token_id = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token;
    setGoogleUser(token_id)
  }

  const currentUserChangedListener = () =>{
    const token_id = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token;
    setGoogleUser(token_id)
    logger.log("in currentUserChangedListener")
  }
  const handleLogin = () => {
    const auth2 = gapi.auth2.getAuthInstance();
    logger.log("Is logged in: ", auth2.isSignedIn.get())
    if (auth2.isSignedIn.get() === false) {
      auth2.signIn()
        .then((response) => {
          logger.log("Google Sigining In")
        })
        .catch((error) => {
          logger.log(error);
        });
      }
  };

  const handleLogout = () => {
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(() => {
      logger.log("User signed out.");
    });
  };

  return (
    <LoginContext.Provider value={{ googleUser, handleLogin, handleLogout }}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
