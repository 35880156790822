import { Link, } from "react-router-dom";
import { AiFillHome, AiFillSetting, AiOutlineInfoCircle, } from "react-icons/ai"
import { IoBookOutline } from "react-icons/io5";


const Sidebar = () => {
    const menuItems = [
        { 'link': '/', 'name': 'dashboard', 'icon': <AiFillHome /> },
        { 'link': '/parasha', 'name': 'parasha', 'icon': <IoBookOutline /> },
        { 'link': '/settings', 'name': 'settings', 'icon': <AiFillSetting /> },
        { 'link': '/about', 'name': 'about', 'icon': <AiOutlineInfoCircle /> },
    ]

    return (

        /*<div className='w-16 fixed top-0 right-0 h-full flex flex-col items-center justify-center'>*/
            <div className='fixed w-full h-16 bottom-0 border-[1px] border-gray-300 bg-white bg-opacity-20 flex flex-row items-center overflow-hidden justify-between px-10'>
                    {menuItems.map((menuItem) => (
                        <Link onClick={() => {}}  key={menuItem.name} to={menuItem.link}>
                            <div className='flex hover:bg-[#55f2fe] p-3 justify-center cursor-pointer font-semibold text-[#2b8b9c]'>
                                <p className="text-2xl">
                                    {menuItem.icon}
                                </p>
                            </div>
                        </Link>
                    ))}
        </div>
    )
}

export default Sidebar