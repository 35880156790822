
const About = () => {
  //const { googleUser, handleLogin, handleLogout} = useContext(LoginContext);

  return (
    <div className='bg-[#ffffff60] shadow-lg w-10/12 h-[90%] flex flex-col items-center gap-6 text-lg'>
    {/*<button onClick={() => {logger.log("AAA"); handleLogout()}} className="font-semibold flex m-auto px-4 py-2 border gap-2 items-center bg-blue-600  border-slate-200 rounded-lg text-slate-200">Disconnect from google</button>
    */}
      <h1 className='tracking-wide mt-16 font-bold text-3xl pt-4'> קצת עלינו </h1>
      

      <p>
      ברוכים הבאים ל"שבת שלי"
      <br/><br/>
      ב"שבת שלי" אנו שואפים לשפר את אווירת החג והשבת כמה שרק אפשר 
      באפליקציה תוכלו להתעדכן בקלות בזמני כניסת ויציאת השבת <br/>
      תוכלו לקבל תזכורת כמה דקות לפני כניסת החג להתכונן ולהדליק נרות <br/>
      ולהעדכן בפרשת השבוע <br/>
      <br/>
      מקווה שתהנו מהשימוש באפליקציה <br/>
      שבת שלום לכולנו <br/>
      <br/>
      ZwiClick <br/>
      </p>

    </div>
  )
}

export default About